.aboutFront {
  height: 100vh;
  background-color: #e27a0c1f;
}

.about {
  overflow-x: hidden;
}

.readmoreBtn button {
  background: linear-gradient(92.48deg, #fa8305 2.08%, #fb9e3c 117.25%);
  border: none;
  border-radius: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.4rem 1rem;
}

.readmoreBtn button p {
  margin: 0;
  color: #ffffff;
}

.aboutFrontTexrt {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aboutcustom-nav {
  background-color: #333333;
  display: flex;
  align-items: center;
  width: fit-content;
  position: absolute;
  bottom: 0px;
  left: 1px;
  z-index: 99;
}

.aboutcustom-nav button {
  background-color: transparent;
  border: none;
  padding: 0.8rem 1rem;
  display: flex;
  align-items: center;
}

.aboutcustom-nav button img {
  height: 0.9rem;
}

.aboutcustom-saperation {
  width: 2px;
  height: 24px;
  background-color: #ffffff;
}

.aboutFrontSlider {
  position: relative;
  height: 100%;
}

.aboutFrontSlider .owl-carousel,
.aboutFrontSlider .owl-stage-outer,
.aboutFrontSlider .owl-stage,
.aboutFrontSlider .owl-item,
.aboutFrontSlider .owl-item div,
.aboutFrontSlider .owl-item div img {
  height: 100%;
}

.aboutFrontTexrtContainer p {
  color: #525252;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 991px) {
  .aboutFront {
    height: 65vh;
  }

  .aboutFrontTexrtContainer h2 {
    font-size: 22px;
  }

  .aboutFrontTexrtContainer p {
    font-size: 15px;
  }
}

@media only screen and (max-width: 576px) {
  .rowReverse {
    flex-direction: column-reverse !important;
  }

  .aboutFront {
    height: auto !important;
  }

  .aboutFrontTexrtContainer {
    margin: 1.5rem 0;
  }
}

/* ----------------------------------------------Hall About Section---------------------------------------------------- */

.raghuHall {
  margin: 4rem auto;
}

.raghuHallContainer {
  background-color: #ffffff;
  box-shadow: 0px 4px 94px 0px #00000026;
  border-radius: 20px;
  padding: 2rem 1.5rem;
}

.aghuHallAboutImg img {
  width: 100%;
}

.reverse {
  flex-direction: row-reverse !important;
}

.aghuHallAboutText {
  display: flex;
  align-items: center;
  height: 100%;
}

.aghuHallAboutText p {
  color: #525252;
  font-size: 15px;
}

@media only screen and (max-width: 576px) {
  .raghuHallContainer {
    padding: 2rem 0.7rem;
  }
}

/* ---------------------------------------------our team section----------------------------------------------------- */

.outeam {
  margin: 2rem 1rem;
  margin-bottom: 3rem;
}

.outeamContaienr {
  text-align: center;
  margin: 2rem 0;
}

.teamCard img {
  width: 6rem;
  border-radius: 50%;
}

.teamCard h6 {
  margin: 0;
}

.teamCard p {
  margin: 0;
  font-size: 14px;
  color: #8b8b8b;
}

.teamCardTxt {
  margin-top: 1.5rem;
}

.teamCard {
  background-color: #f5f5f5;
  padding: 1.5rem 1rem 1.5rem 1rem;
  border-radius: 15px;
  margin-bottom: 1rem;
}

.teamCardsCOntainer {
  margin-top: 2rem;
}

@media only screen and (max-width: 991px) {
  .teamCard img {
    width: 4.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .teamCard img {
    width: 4rem;
  }
}
