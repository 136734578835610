/* @font-face {
    font-family: 'jmh_typewriterregular';
    src: url('../Assets/fonts/Typewriter/jmh_typewriter-webfont.woff2') format('woff2'),
        url('../Assets/fonts/Typewriter/jmh_typewriter-webfont.woff  ') format('woff');
    font-weight: normal;
    font-style: normal;
} */

.page_not_found {
    overflow: hidden;
}

.page_not_found img {
    height: 80%;
    margin-top: -4rem;
}

.page_not_found .text_div h1,
.page_not_found .text_div h5 {
    margin-bottom: 1rem !important;
    font-family: 'jmh_typewriterregular';
    -webkit-text-stroke: 0.4px;
    overflow: hidden;
}

.page_not_found .text_div a {
    text-decoration: none;
    color: rgb(54, 132, 250);
}

.page_not_found .text_div {
    margin-top: -5rem;
    padding: 1rem;
}

.br_hidden {
    display: none;
}

@media screen and (max-width: 576px) {
    .page_not_found img {
        height: 50% !important;
    }

    .page_not_found .text_div h1 {
        margin-bottom: 0.5rem !important;
        font-size: 19px;
    }

    .page_not_found .text_div h5 {
        margin-bottom: 0.5rem !important;
        -webkit-text-stroke: 0.4px;
        font-size: 14px;
        line-height: 1.3;
    }

    .page_not_found .text_div {
        margin-top: -4rem;
        width: 375px;
    }

    .br_hidden {
        display: block;
    }
}

@media screen and (max-width: 400px) {
    .page_not_found .text_div {
        margin-top: -4rem;
        width: 320px;
    }
}