.header .navbar {
  background-color: #ffffffa6;
  padding: 0.5rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  transition: all 0.5s ease-in-out;
}

.navbarRelative {
  position: sticky !important;
  top: 0;
}

.navbarSolid {
  background-color: #ffffff !important;
}

.header .navbar .navbar-brand img {
  width: 200px;
}

.header #navbarNavDropdown ul li a {
  text-decoration: none;
  font-weight: 500;
  font-size: 15px;
  color: #000000;
}

.header #navbarNavDropdown {
  gap: 2rem;
  align-items: center;
}

.header #navbarNavDropdown ul {
  gap: 1rem;
}

.avilbtn {
  background-color: #921414;
  border: none;
  border-radius: 20px;
  padding: 0.4rem 1rem;
  color: #ffffff;
  transition: all 0.3s ease;
}

.avilbtn:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #b10f0f;
}

@media only screen and (max-width: 1030px) {
  .header .navbar {
    padding: 0.5rem 1.5rem;
  }
}

@media only screen and (max-width: 991px) {
  .header .navbar {
    position: sticky !important;
    top: 0;
  }
}

@media only screen and (max-width: 576px) {
  .header .navbar {
    padding: 0.5rem 1.2rem;
  }

  .header .navbar .navbar-brand img {
    width: 150px;
  }

  .header .navbar .navbar-toggler-icon {
    width: 1.2rem;
    height: 1.2rem;
  }
}

@media only screen and (max-width: 400px) {
  .header .navbar {
    padding: 0.5rem 1rem;
  }
}
/* ---------------------------------------------Footer Css--------------------------------------------------- */

.footercontainer {
  background-color: #e0b279;
  padding: 2rem;
  padding-bottom: 1rem;
}

.companyBranding {
  text-align: center;
  margin-bottom: 1.5rem;
}

.companyBranding img {
  width: 220px;
}

.uselInksCOntaier {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.uselInks {
  padding: 1rem;
}

.borderVertical {
  border-right: 1px solid #fff6ea;
}

.uselInksCOntaier ul {
  list-style: none;
  padding: 0;
}

.uselInksCOntaier ul li {
  margin: 0.5rem 0;
  font-size: 15px;
}

.contactCOntaier {
  text-align: center;
  padding: 1rem;
}

.adressContiaer {
  margin-bottom: 1.5rem;
}

.adressContiaer p {
  margin: 0;
  font-size: 15px;
}

.emailCOnriaenr p {
  font-size: 15px;
}

.adressContiaer img {
  width: 1.5rem;
  margin-bottom: 0.6rem;
}

.followUs {
  padding: 1rem;
}

.subscribe input {
  border: none;
  outline: none;
  padding: 0.5rem;
  border-radius: 6px;
  width: 90%;
  margin-top: 0.5rem;
}

.subscribe button {
  border: none;
  background-color: #000000;
  color: #ffffff;
  padding: 0.3rem 1rem;
  font-size: 14px;
  border-radius: 20px;
  transition: all 0.2s ease;
  margin-top: 1.5rem;
}

.subscribe button:hover {
  background-color: #181717;
}

.socialLink {
  margin-top: 2rem;
}

.socialLink ul {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-top: 1rem;
}

.socialLink ul li img {
  width: 2rem;
}

@media only screen and (max-width: 1010px) {
  .uselInks,
  .contactCOntaier,
  .followUs {
    padding: 1rem 0;
  }

  .subscribe input {
    width: 100%;
  }

  .footercontainer {
    padding: 2rem 1rem;
  }

  .paddingZero {
    padding: 0 !important;
  }

  .footerDetails {
    padding: 0 !important;
  }
}
@media only screen and (max-width: 768px) {
  .adressContiaer p,
  .emailCOnriaenr p,
  .uselInksCOntaier ul li {
    font-size: 14px !important;
  }

  .bordernone {
    border: none !important;
  }
}

@media only screen and (max-width: 576px) {
  .bordernone1 {
    border: none !important;
  }
}

.activeNavLink {
  color: #921414 !important;
  font-weight: 600 !important;
}

.checkAvailBtnFixed {
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
  width: 100% !important;
  height: fit-content !important;
  z-index: 999999;
}

.checkAvailBtnFixed a button {
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.checkAvailBtnFixed a button p {
  font-size: 16px !important;
}
.checkAvailBtnFixed a button img {
  width: 1.5rem;
}

.checkBtnHideOnBook {
  display: none !important;
  position: absolute !important;
}

.footerFoot {
  background-color: #ac722c;
  padding: 0.5rem;
}

.footerFootTxt {
  text-align: center;
}
