.queryForm .queryFormImg img {
  width: 100%;
  height: 100%;
}

.queryForm .queryFormImg {
  height: 100%;
}

.slctOcsnCard .slctOcsnCardimg,
.slctOcsnCard .slctOcsnCardimg img {
  width: 100%;
}

.slctOcsnCard .slctOcsnCardimg img {
  border-radius: 20px;
}

.slctOcsnCard .slctOcsnCardimg {
  border: 2px solid #a6a6a6;
  border-radius: 20px;
}

.slctOcsnCard {
  text-align: center;
  padding: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.slctOcsnCardTxt p {
  margin: 0;
}

@media only screen and (max-width: 1200px) {
  .slctOcsnCardTxt p {
    font-size: 14px;
  }

  .slctOcsnCardTxt,
  .slctOcsnCard {
    padding: 0.5rem 0 !important;
  }
}

@media only screen and (max-width: 991px) {
  .slctOcsnCardTxt,
  .slctOcsnCard {
    padding: 0.5rem 0;
  }

  .padding0 {
    padding: 0 !important;
  }
}

.slctOcsnCardTxt {
  padding: 0.5rem;
}

.slctOcsnCard:hover .slctOcsnCardimg,
.slctOcsnCardselected .slctOcsnCardimg {
  border-color: #da6e0b !important;
}

.slctOcsnCard:hover .slctOcsnCardTxt p,
.slctOcsnCardselected .slctOcsnCardTxt p {
  color: #da6e0b !important;
  font-weight: 500;
}

.slctocsnDropdown {
  margin: 2rem auto;
}
.slctocsnDropdown .ocsnDopdown {
  height: 40px;
  background-color: #f4f4f4;
  width: 90%;
  padding: 0.5rem 1rem;
  border-radius: 6px;
}

.p-dropdown-items-wrapper {
  background-color: #f4f4f4;
}

.p-dropdown-items-wrapper ul li {
  margin: 0.4rem 0;
}

.slctOcsnCOntainer {
  text-align: center;
}

.slctOcsnCOntainer h4 {
  margin: 1.5rem 0;
}

.queryFormDetailsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2.5rem;
  height: 100%;
}

.phoneContaienr {
  display: flex;
  align-items: center;
}

.phoneContaienr p {
  margin: 0;
}

.formPath {
  background-color: white;
}
.round12 {
  border: 2px solid #727272;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.round12cmpltd {
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  .round12 {
    width: 40px;
    height: 40px;
  }
}

.round12 p {
  margin: 0 !important;
  color: #727272;
}

.round12cmpltd {
  background-color: #ffffff;
  border-color: #da6e0b;
}

.horizonlineCompltd {
  background-color: #da6e0b !important;
}

.round12cmpltd p {
  color: #ffffff !important;
}

.horizonline {
  width: 15%;
  background-color: #727272;
  height: 2px;
}

@media only screen and (max-width: 576px) {
  .horizonline {
    width: 8%;
  }
}

/* -----------------------------------Calender------------------------------------------------ */

.slctDateContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  height: 90%;
  padding: 1.5rem 0;
  /* margin-top: -5rem; */
}

.valueCard {
  width: 90%;
}

@media only screen and (min-width: 1200px) {
  /* .slctDateContainer {
    margin-top: -5rem;
  } */

  .valueCard {
    width: 90%;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 1200px) {
  .slctDateContainer {
    gap: 1rem;
    justify-content: flex-start;
    margin-top: 2rem;
  }

  .calenderDiv {
    padding: 0.5rem !important;
    margin-bottom: 1rem;
  }
}

.calenderDiv {
  padding: 1rem;
  border: 1px solid #000000;
  border-radius: 15px;
}

.css-1q04gal-MuiDateCalendar-root {
  width: 100% !important;
}

.css-1u23akw-MuiButtonBase-root-MuiPickersDay-root {
  width: 30px !important;
  height: 30px !important;
}

.css-168nijn-MuiPickersDay-root {
  width: 30px !important;
  height: 30px !important;
}

.css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel {
  width: 30px !important;
  height: 35px !important;
}

.css-1q04gal-MuiDateCalendar-root {
  max-height: 290px !important;
}

/* ----------------------------------------------time-------------------------------------------- */

.selectTimecard {
  border: 2px solid #da6e0b;
  border-radius: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.selectTimecard p {
  margin: 0;
  font-weight: 500;
  font-size: 15px;
}

.slctTimeContainer {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 1.5rem 0;
}

.headandcarddiv {
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.headandcarddiv h4 {
  margin-bottom: 1.5rem;
}

@media only screen and (max-width: 991px) {
  .headandcarddiv,
  .slctDateContainer {
    height: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .valueCard {
    margin-bottom: 1.5rem;
  }
}

/* ---------------------------------------Guests----------------------------------------------------- */

.gCountInput {
  width: 94%;
  border: 2px solid #da6e0b;
  height: 50px;
  border-radius: 15px;
  padding: 0.3rem;
  display: flex;
  align-items: center;
}

.gCountInput input {
  height: 100%;
  border: none;
  outline: none;
  width: 100%;
}

.gCountInput .gCountInputImg {
  background-color: #da6e0b;
  padding: 0.5rem 1rem;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.gCountInput .gCountInputImg img {
  width: 1.3rem;
}

.selectguestcard {
  padding: 1rem 0.5rem;
}

.slctGcountContainer {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 1.5rem 0;
}

/* ---------------------------------user Details---------------------------------------- */

.detailsInputContaienr {
  width: 100%;
}

.detailsInputContaienr input {
  height: 43px;
  width: 100%;
  margin-top: 1rem;
  border: 1px solid #d4d2cf;
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
  outline: none;
}

.react-international-phone-input-container {
  margin-bottom: 1rem;
  height: 43px;
  border-radius: 6px;
}

.detailsBtns {
  width: 100%;
  margin: 1.5rem 0;
}

.detailsBtns button {
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  justify-content: center;
  border: none;
  gap: 0.5rem;
  border-radius: 6px;
  margin-bottom: 1rem;
}

.detailsBtns button p {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
}

.googlebtn {
  box-shadow: 0px 2.461538553237915px 9.230770111083984px 0px #0000001c;
  background-color: #ffffff;
  transition: all 0.2s ease-in-out;
}

.googlebtn:hover {
  background-color: #efefef;
}

.pricebnt {
  background-color: #b78b49;
  box-shadow: 0px 2.461538553237915px 9.230770111083984px 0px #0000001c;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
}

.pricebnt:hover {
  background-color: #a17533;
}
/* ------------------------------------------thankyou------------------------------------------------------ */

.thanyoucontainer {
  height: 89vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 991px) {
  .thanyoucontainer {
    height: 40vh;
  }
}

.thnkyMssg {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.thnkyMssg img {
  margin-bottom: 1.5rem;
  width: 4.5rem;
}

.thnkyMssg h4 {
  margin-bottom: 0.8rem;
}

.thnkyMssg button {
  border: none;
  padding: 0.3rem 1rem;
  background-color: #a17533;
  color: #ffffff;
  font-weight: 500;
  border-radius: 6px;
  transition: all 0.2s ease-in-out;
}

.thnkyMssg button:hover {
  background-color: #b78b49;
  box-shadow: 0px 2.461538553237915px 9.230770111083984px 0px #0000001c;
}

@media only screen and (max-width: 576px) {
  .rowSMreverse {
    flex-direction: column-reverse !important;
  }
}
