.galleryFront {
  margin: 7rem 0rem;
}

@media only screen and (max-width: 991px) {
  .galleryFront {
    margin: 2rem 0rem;
    margin-bottom: 4rem;
  }
}

.galleryFrontImg {
  margin-top: 1.5rem;
}

.galleryFrontImg img {
  width: 100%;
}

.galleyCataTabs {
  /* display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap; */
  margin: 2rem auto;
}

.catatab {
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  padding: 1rem;
  border-radius: 7px;
  box-shadow: 7px 10px 4px 0px #00000040;
  margin-bottom: 1rem;
}

.catatab:hover {
  background-color: #f5f5f5;
  color: #921414;
}

.cataTab p {
  margin-bottom: 0.5rem;
}

.catatab h5 {
  font-weight: 500;
  margin: 1rem auto;
  width: fit-content;
}

.catatab img {
  width: 100%;
  height: 323px;
}

.styles-module_navigation__1pqAE {
  height: auto;
  font-size: 35px !important;
}