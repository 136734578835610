::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.margine_left_30px {
    margin-left: 30px !important;
}

.width_100_per {
    width: 100%;
}

.margine_top_40px {
    margin-top: 40px !important;
}

.hidden {
    display: none;
}



.form-control {
    appearance: auto !important;
}

.margin_right_13 {
    margin-right: 13px;
}

.margin_left_13 {
    margin-left: 13px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/* ----------------------------------------------------------------------------------------------------------------------------- */


.display_flex {
    display: flex;
}

.width_33_per {
    width: 33% !important;
}

.red_show {
    color: red;
    font-weight: 800;
}


/* -------------------------------------------------------------------------------------------------------------------------------- */

.dropdown-menu.show {
    left: auto !important;
    right: 0 !important;
    top: 3px !important;
}

.list-group-item-action {
    cursor: default !important;
}

.deletbtnAdmin img {
    width: 1.4rem;
}

.deletbtnAdmin {
    border-color: #d40000;
    border: none;
    border-radius: 5px;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 0.3rem;
}

.deletbtnAdmin p {
    margin: 0;
    color: #d40000;
    font-size: 15px;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
    cursor: text;
}

@media (max-width: 991.98px) {
    .app-header .app-header__mobile-menu {
        order: 0 !important;
    }
}

/* -------------------------------------------------------------------------------------------------------------- */

.displayFlex1 {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.displayFlex1 img {
    width: 2.2rem;
}

.vertical-nav-menu i.metismenu-state-icon,
.vertical-nav-menu i.metismenu-icon {
    width: 20px !important;
}