.frontScreenContainer .sliderItem {
  position: relative;
}

.frontScreenContainer .sliderItemTextCntr {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.frontScreenContainer .sliderItemText {
  text-align: -webkit-center;
  color: #fff;
  background-color: #00000087;
  padding: 0.4rem 1rem;
  width: -moz-fit-content;
  width: 40%;
  perspective-origin: center;
  align-content: center;
}

.frontScreenContainer .owl-prev,
.frontScreenContainer .owl-next {
  font-size: 80px !important;
  font-weight: 300 !important;
  color: #ffffff !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.frontScreenContainer .owl-prev {
  left: 1.5rem;
}

.frontScreenContainer .owl-next {
  right: 1.5rem;
}

.frontBarContaienr {
  background-color: #e0b279;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.frontBarItem img {
  width: 4.5rem;
}

.frontBarItem {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 0.5rem;
}

.frontBarItem h5 {
  margin: 0;
  color: #921414;
}

.frontBarItem p {
  margin: 0;
  color: #921414;
  text-decoration: underline;
  cursor: pointer;
}

.frontBarItem button {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  border: none;
  padding: 0.4rem 1rem;
  border-radius: 20px;
  font-weight: 500;
}

.frontBarItem button img {
  width: 1.5rem;
}

.frontBarItemList {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 0.5rem;
}

@media only screen and (max-width: 1250px) {
  .frontBarItem img {
    width: 4rem;
  }
}

@media only screen and (max-width: 1180px) {
  .frontBarItem img {
    width: 4rem;
  }

  .frontBarItem h5 {
    font-size: 18px;
  }

  .frontBarItem p {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1110px) {
  .frontBarItem img {
    width: 3.5rem;
  }

  .frontBarItem h5 {
    font-size: 16px;
  }

  .frontBarItem p {
    font-size: 14px;
  }

  .frontScreenContainer .sliderItemText {
    color: #fff;
    background-color: #00000087;
    padding: 0.4rem 1rem;
    font-size: 28px !important;
  }
}

@media only screen and (max-width: 925px) {
  .frontBarItem img {
    width: 3rem;
  }

  .frontBarItem h5 {
    font-size: 15px;
  }

  .frontBarItem p {
    font-size: 13px;
  }

  .frontBarItem button img {
    width: 1.2rem;
  }

  .frontBarItemList hr {
    height: 50px !important;
  }
}

@media only screen and (max-width: 860px) {
  .frontBarItem img {
    width: 2.6rem;
  }

  .frontBarItem h5 {
    font-size: 14px;
  }

  .frontBarItem p {
    font-size: 12px;
  }

  .frontBarItem button img {
    width: 1.2rem;
  }

  .frontBarItemList {
    gap: 0.3rem;
  }

  .frontBarItemList hr {
    height: 45px !important;
  }

  .frontScreenContainer .sliderItemText {
    color: #fff;
    background-color: #00000087;
    padding: 0.4rem 1rem;
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 785px) {
  .frontBarCol {
    padding: 0;
  }

  .frontBarItemList hr {
    height: 40px !important;
  }
}

.frontBar {
  transform: translateY(-50%);
  z-index: 999;
  position: absolute;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .frontBar {
    transform: translateY(0%);
    z-index: 999;
    position: absolute;
    width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .frontScreenContainer .sliderItemText {
    color: #fff;
    background-color: #00000087;
    padding: 0.3rem 0.8rem;
    font-size: 17px !important;
  }

  .frontScreenContainer .owl-prev,
  .frontScreenContainer .owl-next {
    font-size: 40px !important;
    font-weight: 300 !important;
    color: #ffffff !important;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .frontScreenContainer .owl-prev {
    left: 1rem;
  }

  .frontScreenContainer .owl-next {
    right: 1rem;
  }

  .frontScreenContainer .owl-carousel {
    height: 35vh;
  }

  .owl-stage-outer {
    height: 100%;
  }

  .owl-carousel .owl-stage,
  .owl-carousel .owl-item,
  .frontScreenContainer .sliderItem,
  .frontScreenContainer .sliderItem img {
    height: 100%;
  }
}

@media only screen and (max-width: 515px) {
  .frontBarItem img {
    width: 2.4rem;
  }

  .frontBarItem h5 {
    font-size: 13px;
  }

  .frontBarItem p {
    font-size: 11px;
  }

  .frontBarItem button img {
    width: 1rem;
  }

  .frontBarItemList {
    gap: 0.2rem;
    height: 40px;
  }

  .frontBarItem button {
    margin-top: 0.5rem;
  }
}

@media only screen and (max-width: 485px) {
  .frontBarItem img {
    width: 2rem;
  }

  .frontBarItem h5 {
    font-size: 12px;
  }

  .frontBarItem p {
    font-size: 12px;
  }

  .frontBarItem button img {
    width: 0.8rem;
  }

  .frontBarItemList {
    gap: 0.2rem;
  }

  .frontBarItem {
    gap: 0.3rem;
  }
}

/* ------------------------------------------Welcome Section------------------------------------------------ */

.welcome {
  margin-top: 5rem;
  background-color: #fff6ea;
  padding: 2rem 1rem;
}

.cursiveHead {
  font-family: "Passions Conflict", cursive;
  font-size: 55px;
  text-align: center;
  margin: 0;
}

.normalHead {
  text-align: center;
}

.wlcmimgtext {
  margin-top: 1.5rem;
}

.wlcmimgtext .wlcmImg,
.wlcmimgtext .wlcmImg img {
  width: 100%;
  height: 100%;
}

.wlcmText {
  padding: 2rem 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.wlcmText h6,
.wlcmText p {
  line-height: 2rem;
  font-size: 18px;
}

.wlcmSign h6 {
  margin: 0;
}

@media only screen and (max-width: 1350px) {

  .wlcmText h6,
  .wlcmText p {
    line-height: 1.7rem;
    font-size: 18px;
  }
}

@media only screen and (max-width: 1250px) {

  .wlcmText h6,
  .wlcmText p {
    line-height: 1.5rem;
    font-size: 18px;
  }
}

@media only screen and (max-width: 1100px) {

  .wlcmText h6,
  .wlcmText p {
    line-height: 1.5rem;
    font-size: 17px;
  }
}

@media only screen and (max-width: 991px) {

  .wlcmText h6,
  .wlcmText p {
    line-height: 1.2rem;
    font-size: 16px;
  }

  .wlcmText {
    padding: 1.5rem 10px;
  }
}

@media only screen and (max-width: 885px) {
  .brhide {
    display: none;
  }
}

/* ------------------------------------------Highlights Section------------------------------------------------ */

.highlights {
  background-image: url("../../../Assests/images/highLightsbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 2rem;
}

.highImg {
  margin-top: 1.5rem;
}

.highImg img {
  width: 100%;
}

.highText {
  background-color: #fff6ea;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.smallhighBorder {
  width: 300px;
  border-bottom: 2px solid #e0b279;
}

.highTextCOntiaenr {
  position: relative;
}

.highTextCOl {
  position: relative;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}

.hightimeTExt {
  font-size: 14px;
  margin-top: 0.5rem;
  margin-bottom: 0;
}

@media only screen and (max-width: 991px) {
  .highText p {
    font-size: 15px;
  }

  .normalHead2 {
    font-size: 24px !important;
  }
}

@media only screen and (max-width: 768px) {
  .highText p {
    font-size: 14px;
  }

  .normalHead2 {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 576px) {
  .highText p {
    font-size: 13px;
    line-height: normal;
  }

  .highTextCOl {
    position: relative;
    transform: translateY(-30%);
    left: 0;
    right: 0;
  }

  .normalHead2 {
    font-size: 18px !important;
  }

  .smallhighBorder {
    width: 250px;
    border-bottom: 2px solid #e0b279;
  }
}

.event-description {
  overflow: hidden;
  max-height: 60px;
  /* Set to the desired height when collapsed */
  opacity: 1;
  transition: max-height 0.5s ease, opacity 0.5s ease;
  /* Add transitions for a smooth effect */
  margin-bottom: 0.5rem;
}

.event-description.expanded {
  max-height: 1000px;
  /* Set to a large value to accommodate varying content height when expanded */
  opacity: 1;
  animation: fadeIn 0.5s ease;
  /* Add a fade-in animation for expansion */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* ------------------------------------------Beaytiful Place Section-------------------------------------- */

.beautyPlace {
  background-color: #fff6ea;
  padding: 2rem 10px;
}

.beautyPlaceContaienr .beautyImg,
.beautyPlaceContaienr .beautyImg img {
  width: 100%;
}

.beautyPlaceContaienr h2 {
  margin-bottom: 1.5rem;
}

.exploreMoreBnt {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem auto 0.5rem auto;
}

.exploreMoreBnt button {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s ease-in-out;
  border-bottom: 2px solid #fff6ea;
}

.exploreMoreBnt button p {
  margin: 0;
  font-size: 15px;
  white-space: nowrap;
}

.exploreMoreBnt button img {
  height: 0.7rem;
  width: 1.2rem;
}

.exploreMoreBnt button:hover {
  border-bottom: 2px solid #e0b279;
}

@media only screen and (max-width: 576px) {
  .marginAfterSm {
    margin-bottom: 0.5rem;
  }
}

/* ------------------------------------------Neary by Section-------------------------------------- */

.nearby {
  margin: 3rem 10px;
  text-align: center;
}

.nearby h4 {
  margin-bottom: 2rem;
}

.nearyByCardDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
}

.nearyByCardDetail img {
  width: 5rem;
  margin-bottom: 0.5rem;
}

.nearyByCardDetail p {
  white-space: nowrap;
}

.nearyByCardsContaienr {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
}

@media only screen and (max-width: 768px) {
  .nearyByCardDetail img {
    width: 4.5rem;
    margin-bottom: 0.5rem;
  }

  .tech-slideshowContainer {
    --total-marquee-items: 5;
    height: 100px;
    width: calc(250px * (var(--total-marquee-items)));
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    margin: 20px auto;
  }

  .tech-slideshow {
    display: block;
    width: 200%;
    position: absolute;
  }

  .tech-slideshow.to-left {
    animation: marqueeLeft 11s linear infinite;
    animation-play-state: running;
  }

  .tech-slideshowContainer:hover .tech-slideshow.to-left {
    animation-play-state: paused;
  }

  @keyframes marqueeLeft {
    0% {
      left: 0;
    }

    100% {
      left: -100%;
    }
  }
}

@media only screen and (max-width: 576px) {
  .nearyByCardDetail img {
    width: 4rem;
    margin-bottom: 0.5rem;
  }
}

/* ------------------------------------------Hall and suites Section-------------------------------------- */

.amenitiesCArds {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
}

.amenitiesCArdsItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
}

.amenitiesCArds img {
  width: 4rem;
}

.amenitiesCArds p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.contactNowBtn button {
  background-color: #b78b49;
  border: none;
  color: #ffffff;
  font-weight: 500;
  border-radius: 20px;
  padding: 0.4rem 1rem;
  transition: all 0.2s ease;
}

.contactNowBtn button:hover {
  box-shadow: 0px 2px 10px 0px #00000040;
}

.contactNowBtn {
  margin-top: 1rem;
}

.hallSuiteImgContaienr img {
  width: 100%;
  height: 100%;
}

.hallImgWrapper {
  position: relative;
}

.Hallcustom-nav {
  position: absolute;
  right: 19px;
  bottom: 1px;
  z-index: 99;
}

.Hallcustom-nav {
  background-color: #ffffff;
  display: flex;
  align-items: center;
}

.Hallcustom-nav button {
  background-color: transparent;
  border: none;
  padding: 0.5rem 0.8rem;
}

.custom-saperation {
  width: 2px;
  height: 30px;
  background-color: #e0b279;
}

.owl-carouselHall,
.owl-carouselHall .owl-stage-outer,
.owl-carouselHall .owl-stage,
.owl-carouselHall .owl-item,
.hallSuiteImgContaienr {
  height: 100%;
}

.owl-carouselHall .owl-nav {
  display: none !important;
}

.hiddenAmenities {
  display: none;
}

@media only screen and (max-width: 991px) {
  .amenitiesCArds p {
    margin: 0;
    font-size: 13px;
    white-space: nowrap;
  }

  .amenitiesCArds {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 0.5rem;
  }

  .contactNowBtn {
    margin-top: 0;
  }

  .hiddenAmenities {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .contactNowBtn {
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 550px) {
  .hideInMob {
    display: none;
  }

  .hiddenAmenities {
    display: flex;
  }
}

@media only screen and (max-width: 430px) {
  .amenitiesCArds img {
    width: 3rem;
  }
}

/* ------------------------------------------events Section-------------------------------------- */

.eventsSLiderContainer {
  padding: 1.5rem 0.5rem;
}

.hallSuiteImgContaienr {
  background-color: #fff6ea;
  margin: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.hallSuiteImgContaienr .imgHead {
  background-color: #e0b279;
  color: #ffffff;
  font-weight: 500;
  width: fit-content;
  padding: 0.3rem 1rem;
  font-size: 15px;
  border-radius: 6px;
  position: relative;
  transform: translateY(-50%);
}

.eventsSLiderContainer,
.eventsSLiderContainer .owl-stage-outer,
.eventsSLiderContainer .owl-stage,
.eventsSLiderContainer .owl-item,
.hallSuiteImgContaienr {
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .hallSuiteImgContaienr .imgHead {
    font-size: 14px;
  }
}

@media only screen and (max-width: 576px) {
  .hallSuiteImgContaienr h5 {
    font-size: 16px !important;
  }
}

/* ------------------------------------------testimonials Section-------------------------------------- */

.testicontainer {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  background-color: #fff6ea;
  padding: 1.5rem;
}

.quoteImg {
  width: 160px !important;
}

.testitext {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
}

.testitextContaienr {
  text-align: left !important;
}

.testitextContaienr img {
  width: 100px !important;
  margin-bottom: 0.5rem;
}

.testitextContaienr p {
  font-size: 13px !important;
}

.testicustom-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
}

.testicustom-nav button {
  background-color: transparent;
  border: none;
  padding: 0.5rem 0.8rem;
}

/* ------------------------------------------blog Section-------------------------------------- */

.blog {
  background-color: #fff6ea;
  padding: 2rem 10px;
  margin-bottom: 3rem;
}

.bogscard {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px 0px #00000040;
  cursor: pointer;
}

.bogscard img {
  width: 100%;
}

.blogtext {
  padding: 1rem;
}

.blogtext p {
  margin: 0;
  font-size: 13px;
}

.blogsCardsContaier {
  margin-top: 1.5rem;
}

a:hover,
a {
  text-decoration: none;
  color: #000;
}

.width_100_per {
  width: 100%;
}


.condition_error {
  color: red;
  font-weight: 500;
}

.display_none {
  display: none;
}

.width_height_50px {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}